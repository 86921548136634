import React, { useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { ContactShadows, Environment, Text, Image } from '@react-three/drei';
import { Leva } from 'leva';
import { useMotionValue, useSpring } from 'framer-motion';
import Selector from '../Selector';
import Stars from '../Stars';
import { useStore } from '../../store';

export default function Hero() {
  const store = useStore();

  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  };

  const smoothMouse = {
    x: useSpring(mouse.x, { stiffness: 75, damping: 100, mass: 3 }),
    y: useSpring(mouse.y, { stiffness: 75, damping: 100, mass: 3 }),
  };

  const manageMouse = (e) => {
    if (store.open) return;
    const { innerWidth, innerHeight } = window;
    const { clientX, clientY } = e;
    const x = clientX / innerWidth;
    const y = clientY / innerHeight;
    mouse.x.set(x);
    mouse.y.set(y);
  };

  useEffect(() => {
    window.addEventListener('mousemove', manageMouse);
    return () => window.removeEventListener('mousemove', manageMouse);
  }, []);

  const handlePointerDown = () => {
      store.open = true
  };


  return (
    <div className="hero-section" style={{ position: 'relative', overflow: 'hidden', height: '100vh', width: '100%', backgroundColor: '#f0f0f0' }}>
      <Canvas eventSource={document.getElementById('root')} eventPrefix="client" camera={{ position: [0, 0, 4], fov: 40 }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>    
        <Image url="/new_logo.png" transparent opacity={1} zoom={0.2} scale={4} position={[0,0.2,0]} >        
        </Image>
        <directionalLight intensity={2} position={[0, 3, 2]} />
        <ambientLight intensity={0.7} />
        <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, -5]} castShadow />
        <Environment preset="sunset" background blur={1} />
        <Selector smoothMouse={smoothMouse}>
          <Stars mouse={smoothMouse} />
        </Selector>
        <ContactShadows resolution={512} position={[0, -0.8, 0]} opacity={1} scale={10} blur={2} far={0.8} />
      </Canvas>
    </div>
  );
}
