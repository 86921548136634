import React from 'react';
import { MeshTransmissionMaterial } from '@react-three/drei';
import { motion } from 'framer-motion-3d';
import { Leva } from 'leva';
import { useTransform } from 'framer-motion';

export default function Mesh({ geometry, materialProps, rotation, position, scale, mouse, multiplier }) {
  const a = multiplier / 2;
  const rotationX = useTransform(mouse.x, [0, 1], [rotation[1] - a, rotation[1] + a]);
  const rotationY = useTransform(mouse.y, [0, 1], [rotation[0] - a, rotation[0] + a]);
  const positionX = useTransform(mouse.x, [0, 1], [position.x - multiplier * 0.5, position.x + multiplier * 0.5]);
  const positionY = useTransform(mouse.y, [0, 1], [position.y + multiplier * 0.5, position.y - multiplier * 0.5]);

  return (
    <>
      <Leva hidden={true} />
      <motion.mesh geometry={geometry} rotation={rotation} scale={scale} position={position} rotation-y={rotationX} rotation-x={rotationY} position-x={positionX} position-y={positionY}>
        <MeshTransmissionMaterial {...materialProps} />
      </motion.mesh>
    </>
  );
}
