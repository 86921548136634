import React, { useRef } from 'react';
import { Float } from '@react-three/drei';
import { useGLTF } from '@react-three/drei';
import { Leva, useControls } from 'leva';
import Mesh from './Mesh';

export default function Stars({ mouse }) {
  const ref = useRef();
  const { nodes } = useGLTF('stars.glb');
  const materialProps = useControls({
    thickness: { value: 0.85, min: 0, max: 3, step: 0.05 },
    roughness: { value: 0.2, min: 0, max: 1, step: 0.1 },
    transmission: { value: 1, min: 0, max: 1, step: 0.1 },
    ior: { value: 0.5, min: 0, max: 5, step: 0.05 },
    chromaticAberration: { value: 0.92, min: 0, max: 1 },
    backside: { value: true },
  });

  return (
    <group ref={ref} position={[0.65, 0.25, 0.85]}>
      <Float rotationIntensity={1}>
        <Mesh geometry={nodes.model_0.geometry} materialProps={materialProps} rotation={[0, 1.2, 0]} position={nodes.model_0.position} scale={0.025} mouse={mouse} multiplier={0.25} />
        <Mesh geometry={nodes.model_1.geometry} materialProps={materialProps} rotation={[0, 1.2, 0]} position={nodes.model_1.position} scale={0.025} mouse={mouse} multiplier={0.75} />
        <Mesh geometry={nodes.model_2.geometry} materialProps={materialProps} rotation={[0, 1.2, 0]} position={nodes.model_2.position} scale={0.025} mouse={mouse} multiplier={0.85} />
      </Float>
    </group>
  );
}

useGLTF.preload('stars.glb');