import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { MeshTransmissionMaterial } from '@react-three/drei';
import { useStore } from '../store';
import { easing } from 'maath';

export default function Selector({ children, smoothMouse }) {
  const ref = useRef();
  const store = useStore();

  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3]);
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store.open ? 0 : 0.1, delta);
    easing.damp3(ref.current.scale, store.open ? 4 : 0.01, store.open ? 0.5 : 0.2, delta);
    easing.dampC(ref.current.material.color, store.open ? '#f0f0f0' : '#ccc', 0.1, delta);
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      store.open = true;
    }, 30000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <mesh ref={ref}>
        <circleGeometry args={[1, 64, 64]} />
        <MeshTransmissionMaterial samples={16} resolution={512} anisotropicBlur={0.1} thickness={0.1} roughness={0.4} toneMapped />
      </mesh>
      <group>
        {children}
      </group>
    </>
  );
}
