import React, { Children, useState } from 'react'
import { motion } from 'framer-motion'
import { useStore } from '../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const container = {
  hidden: { opacity: 0, height: 0, transition: { staggerChildren: 0.05 } },
  show: {
    opacity: 1,
    height: 'auto',
    transition: { when: 'beforeChildren', staggerChildren: 0.05 }
  }
}

const item = {
  hidden: { opacity: 0, y: '100%' },
  show: { opacity: 1, y: 0 }
}

function List({ children, open }) {
  return (
    <motion.ul variants={container} initial="hidden" animate={open ? 'show' : 'hidden'}>
      {Children.map(children, (child) => (
        <li key={child.key}>
          <motion.div variants={item}>{child}</motion.div>
        </li>
      ))}
    </motion.ul>
  )
}

export function Overlay() {
  const state = useStore()
  const [isButtonClicked, setButtonClicked] = useState(false)

  const handleClick = () => {
    state.open = true
    setButtonClicked(true)
  }

  return (
    <>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        {!isButtonClicked && (
          <a onClick={handleClick} href="#" style={{ position: 'absolute', left: "5%", bottom: 45, fontSize: '20px' }}>
            CLICK HERE 4 MORE
          </a>
        )}
      </div>
      {/* <Logo style={{ position: 'absolute', top: 40, left: 40, width: 30 }} /> */}
      {!isButtonClicked && <img src="mini_logo.png" style={{ position: 'absolute', bottom: 40, right: 40, width: 50 }} />}
      <div className="info">
        <List open={state.open}>
          {/* <h1>hamza.si</h1> */}
          <img src="new_logo.png" style={{ width:'300px'}} alt='hamza.si' />
          <h2>Welcome to my site!</h2>
          {/* <p className="blocked">mega cool</p> */}
          <p className="para">
            I don't have much use for this site, but hi anyways. <br/>
            Please send all inquiries to <a href="mailto:email@hamza.si"> email@hamza.si </a>. Alternatively, if you have my number, please call me!
          </p>
          <div>
            <a href='https://www.linkedin.com/in/hamzasi' className="blocked">
              <FontAwesomeIcon icon={faLinkedin} size="1x" />
              LinkedIn
            </a>
            <a href='https://www.instagram.com/icyhamza' className="blocked-insta">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
              Instagram
            </a>
          </div>
        </List>
      </div>
    </>
  )
}
