import { createRoot } from 'react-dom/client'
import './styles.css'
import './app.scss'
import Hero from './components/Hero/Hero'
import { Overlay } from './components/Overlay'

createRoot(document.getElementById('root')).render(
  <>
    <section id="Homepage" style={{ height: '100vh' }}>
      <Hero />
    </section>
    <Overlay />
  </>
)
